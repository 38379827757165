/*------------------------------------------------------------------
[ Global ]*/

@import url("https://fonts.googleapis.com/css?family=Prompt:300,400,500,600,700,800,900");

body,
html {
  font-family: "Prompt";
  color: #666666;
  font-size: 18px;
  line-height: 1.8em;
  @media (max-width: 991.98px) {
    font-size: 16px;
  }
  @media (max-width: 767.98px) {
    font-size: 15px;
  }
}

*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
h1 {
  @media (max-width: 991.98px) {
    font-size: 35px;
  }
}
a {
  color: unset;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
}
a:hover {
  color: unset;
  filter: brightness(0.9);
  text-decoration: none;
}
a:focus {
  outline: none;
}

/*------------------------------------------------------------------
[ text ]*/
.text-indent {
  text-indent: 3em;
}
.text-green1 {
  color: #96c09c;
}
.text-green2 {
  color: #619268;
}
.text-brown {
  color: #c7b263;
}
.text-yellow {
  color: #fffabe;
}
/*------------------------------------------------------------------
[ background ]*/

.bg-green0 {
  background-color: #edf5ed;
}
.bg-green1 {
  background-color: #96c09c;
}
.bg-green1-op {
  background: rgba(150, 192, 156, 0.7);
}
.bg-green2 {
  background-color: #619268;
}
.bg-green2-op {
  background: rgba(97, 146, 104, 0.7);
}
.bg-green3 {
  background-color: #5e7e62;
}
.bg-green4 {
  background-color: #515c53;
}
.bg-brown {
  background-color: #c7b263;
}
.bg-yellow {
  background-color: #fffabe;
}
.bg-gray {
  background-color: #eeeeee;
}
.bg-white-op {
  background-color: rgba(255, 255, 255, 0.8);
}
.bg-banner {
  background: linear-gradient(
      0deg,
      rgba(150, 192, 156, 0.8),
      rgba(150, 192, 156, 0.8)
    ),
    url("../../public/asset/images/bg.jpg") no-repeat;
  background: -webkit-linear-gradient(
      0deg,
      rgba(150, 192, 156, 0.8),
      rgba(150, 192, 156, 0.8)
    ),
    url("../../public/asset/images/bg.jpg") no-repeat;
  background: -moz-linear-gradient(
      0deg,
      rgba(150, 192, 156, 0.8),
      rgba(150, 192, 156, 0.8)
    ),
    url("../../public/asset/images/bg.jpg") no-repeat;
  background: -ms-linear-gradient(
      0deg,
      rgba(150, 192, 156, 0.8),
      rgba(150, 192, 156, 0.8)
    ),
    url("../../public/asset/images/bg.jpg") no-repeat;
  background: -o-linear-gradient(
      0deg,
      rgba(150, 192, 156, 0.8),
      rgba(150, 192, 156, 0.8)
    ),
    url("../../public/asset/images/bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  height: calc(100vh - 56px);
}
.bg-image1 {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../../public/asset/images/bg.jpg") no-repeat;
  background: -webkit-linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../../public/asset/images/bg.jpg") no-repeat;
  background: -moz-linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../../public/asset/images/bg.jpg") no-repeat;
  background: -ms-linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../../public/asset/images/bg.jpg") no-repeat;
  background: -o-linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../../public/asset/images/bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-image2 {
  background: url("../../public/asset/images/concept.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-image3 {
  background: url("../../public/asset/images/bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  height: 80vh;
}
.bg-award1 {
  background: linear-gradient(-90deg, #96c09c 70%, transparent 70%);
}
.bg-award2 {
  background: linear-gradient(90deg, #96c09c 70%, transparent 70%);
}
.bg-award3 {
  background-color: rgba(199, 178, 99, 0.6);
  background: linear-gradient(
    0deg,
    rgba(199, 178, 99, 0.6) 90%,
    transparent 90%
  );
}
.bg-award4 {
  background: linear-gradient(
    90deg,
    rgba(199, 178, 99, 0.6) 70%,
    transparent 70%
  );
}
.bg-award5 {
  background: linear-gradient(
    -90deg,
    rgba(199, 178, 99, 0.6) 70%,
    transparent 70%
  );
}
.bg-award6 {
  background: linear-gradient(0deg, #96c09c 80%, transparent 80%);
}

/*------------------------------------------------------------------
[ Input ]*/

input,
textarea {
  outline: none;
  border: none;
}
input::-webkit-input-placeholder,
input:-moz-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder,
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder {
  color: #cccccc;
}
.input-box {
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
  display: block;
  width: 100%;
  background: #ffffff;
  height: 50px;
  border: 2px solid #c7b263;
  border-radius: 25px;
  padding: 0 30px 0 68px;
}
.textarea-box {
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
  display: block;
  width: 100%;
  background: #ffffff;
  height: 150px;
  border: 2px solid #c7b263;
  border-radius: 25px;
  padding: 20px 30px 20px 68px;
}
.select-box select {
  width: 100%;
  padding: 0 30px 0 68px;
  height: 50px;
  border: 0;
  border-radius: 25px;
  background: #ffffff;
  border: 2px solid #619268;
  color: #666666;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.select-box select:focus {
  outline: none;
}
.select_arrow {
  position: absolute;
  top: 20px;
  right: 20px;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0px 5px;
  border-color: #7b7b7b transparent transparent transparent;
}
.focus-input-box {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: #aa2aff;
}
.input-box:focus + .focus-input-box,
.textarea-box:focus + .focus-input-box,
.select-box select:focus + .focus-input-box {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}
.symbol-input-box {
  font-size: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #666666;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.input-box:focus + .focus-input-box + .symbol-input-box {
  color: #ffb82a;
  padding-left: 28px;
}

/*------------------------------------------------------------------
[ hover ]*/
.hov1:hover {
  color: #96c09c;
  filter: brightness(0.9);
  cursor: pointer;
}
.hov2:hover {
  color: #c7b263;
  filter: brightness(0.9);
  cursor: pointer;
}
.hov3:hover {
  filter: brightness(0.9);
  cursor: pointer;
}

.hov-bg1:hover {
  background-color: #96c09c !important;
  color: #fff;
  cursor: pointer;
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 13px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 13px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate:hover:before {
    visibility: visible;
    opacity: 0;
  }
}

/*------------------------------------------------------------------
[ datepicker ]*/
.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
  display: flex !important;
}

.react-datepicker__time-container {
  float: unset !important;
  border-left: 1px solid #aeaeae;
  width: 70px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 0px !important;
}

.react-datepicker__month-container {
  float: unset !important;
}

.react-datepicker__time-container {
  border-left: 1px solid #aeaeae;
  width: 100% !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  overflow-y: hidden !important;
  margin: unset !important;
  text-align: center;
  width: 269.53px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  list-style: none;
  height: 100% !important;
  overflow-x: scroll !important;
  overflow-y: unset !important;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: content-box;
}

/*------------------------------------------------------------------
[ other ]*/
.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
}

.swiper-pagination-bullet-active {
  background: #96c09c !important;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 0px !important;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0px !important;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: unset !important;
}

/*------------------------------------------------------------------
[ table ]*/
.typeHome .table-sm thead tr td {
  padding-bottom: 8px;
}
.typeHome .table-sm td,
.table-sm th {
  padding: 0px;
}
.typeHome .table-sm tr:nth-child(3n) td:last-child {
  padding-bottom: 20px;
}

.table-sm td,
.table-sm th {
  font-size: 16px;
  padding: 0 !important;
  @media (max-width: 767.98px) {
    font-size: 15px;
  }
}

.table-sm tbody tr td:nth-child(2n) {
  width: 10%;
  white-space: nowrap;
}
